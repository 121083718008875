import React, {useContext, useEffect, useReducer, useState} from 'react'
import './CSS/ShopCategory.css'
import Item from '../Components/Item/Item.jsx'
import Breadcrum from '../Components/Breadcrums/Breadcrum.jsx'
import ItemPatch from '../Components/ItemPatch/ItemPatch.jsx'



const ShopCategory = (props) => {

    const [items, setItems] = useState([]);
    const [products, setProducts] = useState([]);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ limit:100, category: props.category })
    };

    let result_stats = {
      currentPage: null,
      totalPages: null,
      totalResults: null
    }

    const fetchInfo = () => { 
      fetch(window.$BASE_API_URL + '/products',requestOptions) 
        .then((res) => res.json()) 
        .then((data) => {
          setItems(data);
          setProducts(data.products);
        })
    }

    useEffect(() => {
      fetchInfo(props.category); 
    }, [props.category]);

  return (
    <div className="text-black" style={{ 'backgroundColor':'#ccc', 'textTransform':'capitalize' }}>

      <Breadcrum  {...items} links={[ {key:1, 'href':props.category, 'title': props.category } ]} />

    <div className='shop-category my-10 mx-6 md:mx-20 lg:mx-40'>
  
      <div className="shopcategory-indexSort">
        <p><span>Showing {items.currentPage}-{items.totalPages * items.totalResults}</span> out of {items.totalResults} products</p>
        <div className="shopcategory-sort">
          Sort by <img src="dropdown_icon" alt="" />
        </div>
      </div>
      <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-9 box-border object-top text-black">
            {
            products.map((e,i) => {
              if (props.category === 'patches') return  <ItemPatch key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
              else  return  <Item key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
            })
            }
      </div>
      
      <div className="shopcategory-loadmore">
        Explore More
      </div>

    </div>

    </div>
  )
}

export default ShopCategory