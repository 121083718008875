import React from 'react'
import './Breadcrums.css'
import { Link } from 'react-router-dom';

const Breadcrum = ({ links }) => (
  <nav key="breadcrumb_nav" className="w-full shadow-md z-50 font-bold  animate__animated animate__fadeInDownBig" aria-label="Breadcrumb" style={{ background:'rgba(255,255,255,.75)', color:'rgba(0,0,0,.8)', padding: '.5em 10% .5em 10%' }}>
    <Link key="home" to="/">Home</Link>
    {
      links.map((link, i) => {
       return (  <> > <Link key={i} to={'/' + link.href}>{link.title}</Link></> );
      })
    }
  </nav>
);

export default Breadcrum