import React, { useState } from "react";


const LoginSignup = () => {

  const [agreeToTerms, setChecked] = useState(false);
  const [state,setState] = useState("Login");
  const [formData,setFormData] = useState({ username:"",email:"",password:"", agreeToTerms: agreeToTerms });

  const changeHandler = (e) => {
    setFormData({...formData,[e.target.name]:e.target.value});
  }

  const login = async () => {
    let dataObj;
    await fetch('http://localhost:4000/login', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'Content-Type':'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => {dataObj=data});
      console.log(dataObj);
      if (dataObj.success) {
        localStorage.setItem('auth-token',dataObj.token);
        window.location.replace("/");
      } else {
        alert(dataObj.errors)
      }
  }

  const signup = async () => {
    
    if(agreeToTerms !== true) { alert('agree to the terms of use & privacy policy'); return false; }

    let dataObj;
    await fetch('http://localhost:4000/signup', {
      method: 'POST',
      headers: {
        Accept:'application/form-data',
        'Content-Type':'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => {dataObj=data});

      if (dataObj.success) {
        localStorage.setItem('auth-token',dataObj.token);
        window.location.replace("/");
      } else {
        alert(dataObj.errors)
      }
  }

  return (
    <div className="color-[#333] full-width h-[90vh] pt-[100px] text-center">
      <div className="loginsignup-container" className="text-left w-[90%] max-w-[550px] m-auto py-10 px-15 border-box bg-[#fff]/90 px-10 rounded-lg">
        <h1 className="text-2xl text-black">{state}</h1>
        <div className="loginsignup-fields flex flex-col gap-2 mt-[20px]">
          {state==="Sign Up"?<input type="text" placeholder="Your name" name="username" value={formData.username} onChange={changeHandler}  className="text-black mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none " />:<></>}
          <input type="email" placeholder="Email address" name="email" value={formData.email} onChange={changeHandler} className="text-black mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none " />
          <span className="text-sm text-gray-500">Use a permanent address where you can receive mail. </span>
          <input type="password" placeholder="Password" name="password" value={formData.password} onChange={changeHandler} className="text-black mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none " />
          {state==="Sign Up"?<input type="password" placeholder="Confirm Password" name="confirmpass" value={formData.confirmpass} onChange={changeHandler} className="text-black mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none " />:<></>}
        </div>
        
        {state==="Sign Up"?<div className="loginsignup-agree mt-4">
          <input type="checkbox" name="agreeToTerms" id="agreeToTerms" defaultChecked={false} onChange={() => setChecked(!agreeToTerms)} />
          <label for="agreeToTerms" className="text-sm pl-2 cursor-pointer text-gray-700">By continuing, I agree to the terms of use & privacy policy.</label>
        </div>:<></>}

        <button  onClick={()=>{state==="Login"?login():signup()}} class="w-full mt-4 block  border-[darkred] bg-[darkred] hover:border-white hover:bg-[darkred] text-white bg-opacity-90 hover:bg-opacity-100 border-opacity-50  drop-shadow px-10 py-2 rounded inline-block ">Continue</button>

      </div>
       {state==="Login"?
        <p className="loginsignup-login mt-4 text-gray-300">Create an account? <a className="cursor-pointer underline text-white" onClick={()=>{setState("Sign Up")}}>Click here</a></p>
        :<p className="loginsignup-login mt-4 text-gray-300">Already have an account? <a  className="cursor-pointer underline text-white" onClick={()=>{setState("Login")}}>Login here</a></p>}
    </div>
  );
};

export default LoginSignup;
