import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Breadcrum from '../Components/Breadcrums/Breadcrum';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import RelatedProducts from '../Components/RelatedProducts/RelatedProducts';
import NewsLetter from "../Components/Newsletter/NewsLetter";


const BASE_API_URL = (process.env.NODE_ENV !== 'production')? process.env.REACT_APP_DEV_APIURL : process.env.REACT_APP_PROD_APIURL;

const Product = () => {

  let {productId} = useParams();
  const [data, setProduct] = useState([]);


  const requestOptions = {
    method: "GET",
  };

  let result_stats = {
    currentPage: null,
    totalPages: null,
    totalResults: null
  }

  const fetchInfo = () => {
    axios.get(window.$BASE_API_URL + '/product?id=' + productId)
    .then((resp) => { 
      setProduct(resp.data); 
      //setProductPhotos(resp.data.images);
    })
    .catch((error) => {
      console.error("Error fetching product data: ", error);
    });
  };



  useEffect((productId) => {
    fetchInfo();
  }, [productId]);


  /*
  let {productId} = useParams();

  const retrievePosts = async (r) => {
    const response = await axios.post(
    "http://localhost:4000/product",{
      id:productId
    });
    return response.data;
  };

  const {
    data: data,
    error,
    isLoading,
  } = useQuery(['postsData',productId], ()=> retrievePosts(productId));

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
*/




  return (
    
    <div className="mt-90">
      <Breadcrum  {...data} links={[
          {'href':'headwear', 'title': data.category },
          {'href':data.category + '/' + productId, 'title':  data.title },
       ]} />

      <div className="mx-[3%] sm:mx-[5%] md:mx-[8%] lg:mx-[15%]">
        <ProductDisplay {...data} imgArr={data.images} />
      </div>  

        <div className="text-black mt-20 px-[3%] sm:px-[5%] md:px-[8%] lg:px-[15%]" style={{ 'backgroundColor':'#ccc', 'textTransform':'capitalize' }}>
          <RelatedProducts {...data} />
        </div>
      
        <div className="section block py-10 bg-white">
        <NewsLetter />
      </div>

    </div>
  )

}

export default Product;