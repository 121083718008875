import React, { useEffect, useState } from "react";
// import './Popular.css';
import Item from '../Item/Item';



const Artisan = () => {

  const BASE_API_URL = (process.env.NODE_ENV !== 'production')? process.env.REACT_APP_DEV_APIURL : process.env.REACT_APP_PROD_APIURL;

    const [allproducts, setAllProducts] = useState([]);
    const fetchInfo = () => { 
      fetch(BASE_API_URL + '/artisan') 
        .then((res) => res.json()) 
        .then((data) => setAllProducts(data))
    }
    useEffect(() => {
      fetchInfo();
    }, []);

    return (



      <div id="artisan-divider" className='popular mx-6 md:mx-20 lg:mx-40'>
          <h1 className="text-5xl text-black tracking-wide awakening-regular text-center text-bold mb-10">HANDMADE Artisan Line</h1>

          <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 box-border object-top text-black">
            {allproducts.map((e,i) => {
              return  <Item key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
            })}
          </div>

          <div className="text-gray-400 text-lg leading-loose gap-4 mt-5 text-center animate__animated animate__slideInUp">
              <p>With the efforts of our artist and designer, each hat is detailed with a touch of rebellion, painstakingly handcrafted, and of the highest quality. The entire process involves many hours of hands-on craftsmanship, which in turn limits the total number of Artisan hats that we're able to create and offer at any given time. Due to the high demand for our Artisan hats and the limited availability, these hats often sell out within hours (and sometimes even minutes) after being made available.</p>
              <p>Like any work of art, our Artisan hats are strictly sold on a first come first serve basis.</p>
          </div>
      </div>
    )
}

export default Artisan