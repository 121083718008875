import React from 'react';
import './Hero.css';
import arrow_icon from  '../Assets/arrow.png';
import img1 from './images/laice_babe_rocker_hat_skull.jpg';
import img2 from './images/Hero01.jpg';
import img3 from './images/Hero02.jpg';
import img4 from './images/Hero03.jpg';

import { ImageSlider } from '../ImageSlider/ImageSlider.tsx';

const imgArr = [img1, img2, img3, img4];

const Hero = () => {

  return ( 
    <div className="relative w-full max-h-full md:max-h-[100vh] overflow-hidden">
        <div className="z-10 top-40 left-40 absolute hero-left invisible md:visible">
            <h2 className="animate__animated animate__rollIn animate__delay-1s animate__faster">New Arrivals Only</h2>
            <div className="hero-hand-icon animate__animated  animate__fadeInLeftBig animate__delay-1s">
                <p>new 
                collections 
                for everyone
                </p>
            </div>
            <div className="hero-latest-btn animate__animated  animate__bounceInUp animate__flipInX  animate__delay-1s"> 
                <div>Latest Collection</div>
                <img src={arrow_icon} alt="" />
            </div>
        </div>
        <ImageSlider imageUrls={imgArr} />
    </div>
    )

}

export default Hero