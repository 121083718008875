import React from 'react'
import './ItemPatch.css'
import { Link } from 'react-router-dom'

const ItemPatch = (props,i) => {

    // console.log(props);
  return (
      <Link key={i} to={`/product/${props.id}`} className="text-center relative transition ease-in-out hover:scale-105 drop-shadow-md" >
        { props.images.map(function(key,i) {
          return ( <img key={i} onClick={window.scrollTo(0, 0)} src={ key[i].files.md}  className="block aspect-square object-scale-down rounded-lg drop-shadow-md" alt={props.title} /> )
        })}
        <p className="block text-center text-xl mt-4">{props.title}</p>
        <div className="item-prices">
          <div className="item-price-new">Price: ${props.price} USD</div>
          <div className="item-price-old">${props.price}</div>
        </div>
      </Link>      
  )
}

export default ItemPatch