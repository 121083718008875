import React from 'react'
import { Link } from 'react-router-dom'



const Item = (props,i) => {


  return (
      <Link key={i} to={`/product/${props.id}`} className=" relative transition ease-in-out hover:scale-105 drop-shadow-md" >
        <div className="ribbon red"><span>Sold</span></div>
        { props.images.map(function(key,i) {
          return ( <img key={i} onClick={window.scrollTo(0, 0)} src={ key[i].files.md} className="block aspect-[4/3]  object-cover  rounded-lg drop-shadow-md hover:border-1 border-white" alt={props.title} /> )
        })}
        <p className="block text-center text-xl mt-4">{props.title}</p>
        <div className="item-prices">
          <div className="item-price-new">${props.price}</div>
          <div className="item-price-old">${props.price}</div>
        </div>        
      </Link>
  )
}

export default Item