import React, { useEffect, useState } from "react";
import ItemPatch from "../ItemPatch/ItemPatch";
import { Link } from "react-router-dom";

const BASE_API_URL = (process.env.NODE_ENV !== 'production')? process.env.REACT_APP_DEV_APIURL : process.env.REACT_APP_PROD_APIURL;


const LeatherPatches = () => {

  const [products, setProducts] = useState([]);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ category: "leather patches", limit:6 })
  };

  const fetchInfo = () => { 
    fetch(BASE_API_URL + '/products',requestOptions) 
      .then((res) => res.json()) 
      .then((data) => setProducts(data.products))
  }

  useEffect(() => {
    fetchInfo();
  }, []);
  
  return (
    <div className='new-collections mx-6 md:mx-20 lg:mx-40'>
      
        <h1 className="text-5xl tracking-wide awakening-regular mb-10 awakening-regular text-center">Graphic Leather Patches</h1>

        <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-9 box-border object-top text-white">
            {products.map((e,i) => {
              return  <ItemPatch key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
            })}
        </div>

        <Link to='/patches' style={{ textDecoration:'none'}}><button className="hero-latest-btn btn-green animate__animated  animate__slideInUp animate__flipInX  animate__delay-1s"> 
            Shop Leather Patches
        </button></Link>

    </div>
  )
}

export default LeatherPatches