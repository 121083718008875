import React from 'react'
import './CSS/Contact.css'

function Contact() {
  return (
    <div className="contact-container">


        <p>Whether you're looking for answers, would like to solve a problem, or just want to let us know how we did, you'll find many ways to contact us right here. Communication with our clients is our number one priority.</p>
        
        <div className="container mt-5">
            <h2>Contact Us</h2>
            <form>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Your Name</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Your Email</label>
                    <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" rows="3" placeholder="Enter your message"></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
            
        
        <div className="">
            <a href="//www.facebook.com/boneheadwear/" target="_new" className="social facebook" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on Facebook" data-original-title="" title="">
                <i className="fa fa-facebook fa-4x"></i>       
            </a>
            <a href="//www.twitter.com/boneheadwear/" target="_new" className="social twitter" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on Twitter" data-original-title="" title="">
                <i className="fa fa-twitter fa-4x"></i>   
            </a>
            <a href="//www.pinterest.com/boneheadwear/" target="_new" className="social pinterest" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on Pinterest" data-original-title="" title="">
                <i className="fa fa-pinterest fa-4x"></i>  
            </a> 

            <a href="//www.linkedin.com/company/bonehead-wear" target="_new" className="social linkedin" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on LinkedIn" data-original-title="" title="">
                <i className="fa fa-linkedin fa-4x"></i>   
            </a>
            <a href="skype:cjwilson3759?call" target="_new" className="social skype" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on Skype" data-original-title="" title="">
                <i className="fa fa-skype fa-4x"></i>       
            </a>
            <a href="//www.youtube.com/boneheadwear/" target="_new" className="social youtube" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on YouTube" data-original-title="" title="">
                <i className="fa fa-youtube fa-4x"></i>     
            </a>
            <a href="//boneheadwear-blog.tumblr.com/" target="_new" className="social tumblr" data-rel="tooltip" data-toggle="tooltip" data-trigger="hover" data-placement="bottom" data-title="Reach us on Tumblr" data-original-title="" title="">
                <i className="fa fa-tumblr fa-4x"></i>     
            </a>
        </div>
    </div>
  )
}

export default Contact