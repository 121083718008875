import React, { useEffect, useState } from "react";
import './NewCollections.css'
import Item from '../Item/Item'
import arrow_icon from  '../Assets/arrow.png';
import { Link } from "react-router-dom";


const BASE_API_URL = (process.env.NODE_ENV !== 'production')? process.env.REACT_APP_DEV_APIURL : process.env.REACT_APP_PROD_APIURL;

const NewCollections = () => {
  const [products, setProducts] = useState([]);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ category: "headwear", limit:6 })
  };

  const fetchInfo = () => { 
      fetch(BASE_API_URL + '/products',requestOptions) 
      .then((res) => res.json()) 
      .then((data) => setProducts(data.products))
  }

  useEffect(() => {
    fetchInfo();
  }, []);



  return (
    <div id="featured-artisan" className='popular mx-6 md:mx-20 lg:mx-40'>
        <h1 className="text-5xl text-center tracking-wide awakening-regular mb-6 awakening-regular mb-10">FEATURED ARTISAN HATS</h1>

        <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 box-border object-top text-white">
            {products.map((e,i) => {
              return  <Item key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
            })}
        </div>

        <div className="hero-latest-btn btn-green animate__animated  animate__bounceInUp animate__flipInX  animate__delay-1s"> 
          <Link to='/headwear' className="px-10 py-3 my-auto w-200" style={{ textDecoration:'none'}}><div>Shop Headwear</div></Link>
        </div>
    </div>
  )
}

export default NewCollections