import React from 'react';
import './CSS/About.css';

function About() {

    return (
        <div className="container mx-auto">

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12 mb-12">
                <div>
                    <img src="https://source.unsplash.com/featured/?rock-music" className="w-90 h-auto" alt="Rocker Style Head Wear" />
                </div>
                <div className="text-lg leading-loose gap-4 mt-5  mb-20">
                    <h2 className="text-3xl text-white font-bold">About Us</h2>
                    <p className="mt-4">Established in 2013, Bonehead Wear was born with a mission: to craft one-of-a-kind, bold, and personalized headwear pieces that celebrate your individuality</p>
                    <p className="mt-4">We are dedicated to providing you with the coolest and most unique alternative rocker style clothing. Our passion for music and fashion drives us to create designs that resonate with the rock culture.</p>
                    <p className="mt-4">Our mission is to provide high-quality, handcrafted headwear that complements your unique personality and style. Whether you're a musician, artist, or simply someone who loves to stand out, we have the perfect headwear for you. Each piece in our collection is designed with attention to detail and a rebellious spirit. From studded leather caps to bold graphic beanies, we offer a wide range of headwear options to help you express yourself.</p>
                    <p className="mt-4">Join us in celebrating individuality and creativity with our rocker-inspired headwear. Explore our collection today and rock your style!</p>
                    <p className="mt-4">"In a world where conformity reigns supreme in headwear, we're here to disrupt the status quo."</p>
                </div>
            </div>
        </div>
    )

}





export default About