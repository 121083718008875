import React, { useEffect, useState } from 'react'
import Hero from '../Components/Hero/Hero.jsx'
import Artisan from '../Components/Artisan/Artisan'
import Offers from '../Components/Offers/Offers'
import NewCollections from '../Components/NewCollections/NewCollections'
import NewsLetter from '../Components/Newsletter/NewsLetter' 
import ShoutOut from '../Components/ShoutOut/ShoutOut'
import PhotoWall from '../Components/PhotoWall/PhotoWall'
import LeatherPatches from '../Components/LeatherPatches/LeatherPatches'
import { products } from '../Context/productsStore.js';


const Home = () => {
  return (
    <div>
      <div>
        <Hero  className="border-b-2 border-black" />
      </div>

      <div className="section block py-10 leading-tight bg-[rgba(255,255,255,.9)]">
        <Artisan />
      </div>

      <div id="section-offers" className="section bg-cover bg-center pt-10 border-b-1 border-white-900">
        <Offers />
      </div>

      <div className="section block py-10 bg-gray">
        <NewCollections />
      </div>

      <div className="section block py-10 m-auto px-5 md:px-20 lg:px-40 bg-black">
        <ShoutOut />
      </div>
      
      <div id="home_leather_patches" className="section block py-10 m-auto px-5 md:px-20 lg:px-20 xl:px-40">
        <LeatherPatches />
      </div>

      <div className="section block py-10 bg-white">
        <NewsLetter />
      </div>
      <PhotoWall />

    </div>
  )
}


export default Home