import React from 'react'
import './Offers.css'
import exclusive_image from '../Assets/back2back-guitar-babes.png'

const Offer = () => {
  return (

    <div className="flex-container">
      <div className="flex-items">
          <h1 className="leading-tight text-3xl md:text-5xl lg:text-6xl font-extrabold">Exclusive Offers for You</h1>
          <p>ONLY ON BEST SELLING PRODUCTS</p>
          <button className="px-10 py-3">Check Now</button>
      </div>
      <div className="flex-items">
        <img src={exclusive_image} className="rounded-s size-fit" alt="" />
      </div>
    </div>

  )
}

export default Offer