import React from 'react';
import './PhotoWall.css';
import { photos } from "../Assets/photowall";




function PhotoWall() {

  return (
    <div className="pb-4 bg-white">
    <div className="masonry-container">
        { photos.map((item,i)=>{
            return <div key={i} className="masonry-item"><img src={item.src} id={i} /></div>
        }) } 
    </div>
    </div>
  )
}

export default PhotoWall