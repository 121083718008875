import React, { useId, useContext,useRef,  useState, useEffect } from "react";
import "./ProductDisplay.css";
import ShopPaySVG from "../Assets/logo-shoppay.svg";
import { CartContext } from '../../Context/CartContext';



const ProductDisplay = (props) => {
    const {images = []} = props;
    const [open, setOpen] = useState(false)
    const cart = useContext(CartContext);
    const id = useId();
    const product_photos = (typeof props.images != 'undefined')?  props.images : [];


const ProductImageViewer = (props) => {
    const product_photos = (typeof props.images !== 'undefined') ? props.images : [];
    const [previewIMG, setPreviewIMG] = useState(product_photos.length > 0 ? product_photos[0].files.lg : '');
    const [open, setOpen] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });
    const imgRef = useRef(null);
    const [maxScale, setMaxScale] = useState(1);
    

    useEffect(() => {
        const handleLoad = () => {
            if (imgRef.current) {
                const naturalWidth = imgRef.current.naturalWidth || 640;
                const naturalHeight = imgRef.current.naturalHeight || 480;
                const containerWidth = imgRef.current.clientWidth || 640;
                const containerHeight = imgRef.current.clientHeight || 480;

                const widthScale = naturalWidth / containerWidth;
                const heightScale = naturalHeight / containerHeight;

                setMaxScale(Math.max(widthScale, heightScale) * 2);
            }
        };

        if (imgRef.current) {
            imgRef.current.addEventListener('load', handleLoad);
        }

        return () => {
            if (imgRef.current) {
                imgRef.current.removeEventListener('load', handleLoad);
            }
        };
    }, [previewIMG]);

    
    useEffect(() => {
        if (open) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [open]);

    const preview_classes = (props.category === 'Leather Patches') ?
        'width-full block object-scale-down rounded-lg cursor-zoom-in' :
        'width-full block object-cover rounded-lg cursor-zoom-in';

    const thumb_classes = (props.category === 'Leather Patches') ?
        'width-full object-scale-down rounded-lg cursor-pointer' :
        'width-full aspect-[4/3] object-cover rounded-lg cursor-pointer mb-2';

    const onScroll = (e) => {
        const delta = e.deltaY * -0.002;
        let newScale = pos.scale + delta;
  
        // Set zoom limits
        const minScale = 1; // Minimum scale limit

        if (newScale < minScale) newScale = minScale;
        if (newScale > maxScale) newScale = maxScale;

        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const newX = mouseX - (mouseX - pos.x) * (newScale / pos.scale);
        const newY = mouseY - (mouseY - pos.y) * (newScale / pos.scale);

        setPos({ x: (newScale > 1) ? newX : 0, y: (newScale > 1) ? newY : 0, scale: newScale });

        e.stopPropagation();
    };

    
        return (
            <>
                <div 
                    onClick={() => setOpen(false)} 
                    className={`fixed inset-0 flex justify-center items-center z-[600] overflow-hidden transition-colors px-20 ${open ? "visible bg-black/95" : "invisible"}`}
                >
                    <div onWheelCapture={onScroll}>
                        <div className="absolute inline-block top-10 right-10 background-white z-[601]">
                            <button 
                                className="p-1 rounded-lg text-gray-300 bg-opacity-0 font-bold hover:text-white text-3xl" 
                                onClick={() => setOpen(false)}
                            >
                                x
                            </button>
                        </div>
                        <img 
                            ref={imgRef}
                            className="object-scale-down overflow-hidden rounded-lg max-h-[95vh]" 
                            src={previewIMG} 
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                transformOrigin: "0 0",
                                transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                            }} 
                            onLoad={() => {
                                if (imgRef.current) {
                                    const naturalWidth = imgRef.current.naturalWidth || 640;
                                    const naturalHeight = imgRef.current.naturalHeight || 480;
                                    const containerWidth = imgRef.current.clientWidth || 640;
                                    const containerHeight = imgRef.current.clientHeight || 480;
            
                                    const widthScale = naturalWidth / containerWidth;
                                    const heightScale = naturalHeight / containerHeight;
            
                                    setMaxScale(Math.max(widthScale, heightScale) * 2);
                                }
                            }}
                        /> 



                    </div>
                </div>
    
                <div className="relative block">
                    <div className="ribbon red">
                        <span>{props.stock === 0 ? 'Sold' : props.stock === 1 ? 'Last One' : 'Available'}</span>
                    </div>
                    <img 
                        className={preview_classes} 
                        src={previewIMG} 
                        alt={props.title} 
                        onClick={() => setOpen(true)} 
                        id="img_preview" 
                    />
                </div>
                <div className="columns-3 gap-2 mt-[1em]">
                    {product_photos.map(d => (
                        <img  
                            className={thumb_classes} 
                            src={d.files.md} 
                            onClick={() => {
                                setPreviewIMG(d.files.lg);
                                setPos({ x: 0, y: 0, scale: 1 });  // Reset position and scale on thumbnail click
                            }} 
                            key={Math.random().toString(16).slice(-4)} 
                        />
                    ))}
                </div>
            </>
        );
    };



    return (
        
    <div className="box-border">
        


        <div class="md:grid md:grid-cols-2 grid-flow-col gap-10 mt-[120px]">
            <div class="gap-4">
                <ProductImageViewer  {...props} />
            </div>

            <div class="text-white text-xl">
                <h1 className="text-5xl tracking-wide awakening-regular">{props.title} </h1>

                <div className="productdisplay-right-stars">
                    <span className="fa fa-star ml-2 text-2xl text-yellow-300"></span>
                    <span className="fa fa-star ml-2 text-2xl text-yellow-300"></span>
                    <span className="fa fa-star ml-2 text-2xl text-yellow-300"></span>
                    <span className="fa fa-star ml-2 text-2xl text-yellow-300"></span>
                    <span className="fa fa-star ml-2 text-2xl opacity-40"></span>
                    <span className="ml-4 text-sm">(122)</span>
                </div>
                
                <div className="mt-[.5em]">
                    { props.sale !== undefined && props.sale > 0 && ( <span className="price-orig text-2xl  mr-[1em] line-through  opacity-40">${props.price.toFixed(2)}</span> )}
                    <span className="price-current text-2xl text-green mr-4">${ ( props.sale !== undefined && props.sale > 0)? props.price * ((100 - props.sale) / 100) : props.price }</span>
                    <span class="shopify-installments__content text-sm opacity-80 inline-block" id="shopify-installments-content">
                    or 4 interest-free payments of <b>${props.price/4}</b> with <img src={ShopPaySVG} className="h-[18px] inline-block" />
                    </span>
                </div>

                <div className="mt-[1em]">
                    <h1>Select Size</h1>
                    <div className="productdisplay-right-sizes mt-[.5em]">
                        {(() => {
                        if(props.category === 'Headwear' ){
                        return (  
                            <>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">SM / MD</button>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-8">LG / XL</button> 
                            </>
                        );
                        } else if(props.category === 'Leather Patches' ){
                        return (  
                            < >
                            </>
                            )
                        } else {
                        return (  
                            <>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">S</button>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">M</button>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">L</button>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">XL</button>
                            <button className="border border-white bg-white bg-opacity-10 border-opacity-50 rounded px-6 py-3 mr-4">XXL</button>    
                            </>
                        );   
                                
                        }
                        })()}
                        <button onClick={() => cart.addOneToCart(props._id)} className="border border-[darkred] bg-[darkred] text-white bg-opacity-90 hover:bg-opacity-100 border-opacity-50 rounded drop-shadow px-10 py-3 inline-block mt-4 pointer-events-auto">ADD TO CART</button>
                    </div>
                    <p className="block mt-[2em] text-base" style={{whiteSpace: "pre-wrap"}}>{ props.description }</p>
                </div>

            </div>

        </div>

    </div>
  );
};

export default ProductDisplay;