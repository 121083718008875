import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link  } from 'react-router-dom';
import { MenuIcon, XIcon, ShoppingCartIcon } from '@heroicons/react/outline';
import { CartContext } from "../../Context/CartContext";
import { ReactComponent  as Logo } from './images/boneheadwear_text.svg'


const CartItem = ({ product, onUpdate, onRemove }) => {
  const [quantity, setQuantity] = useState(product.quantity);
  const cart = useContext(CartContext);

  useEffect(() => {
    setQuantity(product.quantity);  // Sync with global state
  }, [product.quantity]);  // Re-run when product.quantity changes

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
    onUpdate(product.id, newQuantity);
  };

  return (
    <tr>
      <td className="size-16">
        <img src={product.img} className="aspect-square rounded object-cover"  />
      </td>
      <td>
        <span className="font-semibold">{product.title}</span><br />
        ${product.price.toFixed(2)} x 

        <button  onClick={() => cart.removeOneFromCart(product.id)} className="border rounded bg-gray-100 px-2 text-gray-400 hover:text-gray-600 ml-2 text-bold">-</button>
        <input type="text" name="quantity" onChange={handleQuantityChange} value={quantity} min="0" className="border bg-gray-100 px-2 w-12 text-center" readOnly={true} />
        <button onClick={() => cart.addOneToCart(product.id)} className="border rounded bg-gray-100 px-2 text-gray-400 hover:text-gray-600 mr-2 text-bold">+</button>
         
        <button onClick={() => cart.deleteFromCart(product.id)} className="border rounded bg-gray-100 px-2 text-gray-400 hover:text-gray-600">
          <i className='fa fa-trash'></i>
        </button>
      </td>
    </tr>
  );
};


const Navbar = () => {
    const cart = useContext(CartContext);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    const [isOpen, setIsOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);

    return (
        <nav className="bg-black text-white  z-[52] relative ">
            <div className="px-4">
                <div className="flex justify-between h-12">
                    <div className="flex">
                        {/* Logo Section */}
                        <div className="flex-shrink-0 flex items-center">
                          <NavLink to='/' style={{ textDecoration:'none'}}><svg className="animate__animated  animate__zoomIn" style={{ 'maxWidth':'215px', 'maxHeight':'45px', 'marginBottom':'-21px' }}><Logo fill="blue" width="100%" height="100%" /></svg></NavLink>
                        </div>

                        {/* Primary Nav Menu */}
                        <div className="hidden items-center py-2  md:block">
                            <div className="ml-10 flex items-baseline space-x-4 flex-grow basis-[100%] items-center ">
                              <NavLink  to='/' className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Shop</NavLink >
                              <NavLink  to='/headwear' className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Headwear</NavLink >
                              <NavLink  to='/patches' className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Patches</NavLink >
                              <NavLink  to='/clothing' className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Clothing</NavLink >
                            </div>
                        </div>
                    </div>

                    {/* Cart Icon */}
                    <div className="flex items-center">
                        <button onClick={() => setCartOpen(!cartOpen)} className="p-1 rounded-full hover:text-gray-300">
                            <ShoppingCartIcon className="h-6 w-6" /> 
                            {productsCount > 0 ?<span className="block text-xs bg-red-700 rounded-full h-4 w-4 absolute right-3 top-2">{productsCount}</span>:<></>}
                        </button>

                        {/* Mobile Menu Button */}
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md hover:text-gray-400 hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                            >
                                {isOpen ? <XIcon className="block h-6 w-6" /> : <MenuIcon className="block h-6 w-6" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                      <NavLink  to='/' className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Shop</NavLink >
                      <NavLink  to='/headwear' className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Headwear</NavLink >
                      <NavLink  to='/patches' className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Patches</NavLink >
                      <NavLink  to='/clothing' className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700">Clothing</NavLink >
                    </div>
                </div>
            )}

            {/* Cart Dropdown */}
            {cartOpen && (
              <div className="absolute right-0 mt-2 w-[400px] md:w-[25vw] bg-white rounded-md shadow-lg py-1 z-50 grid place-items-center mr-1 text-gray-700 animate__animated animate__slideInRight animate__faster">
                {productsCount > 0 ? (
                  <>
                    <div className="font-semibold bg-white text-lg border-b border-dotted border-gray-700 w-full text-center pb-1">
                      Items in your cart
                    </div>
                    <table className="w-full" cellPadding="4">
                      {cart.items.map((product) => (
                        <CartItem
                          key={product.id}
                          product={product}
                          onUpdate={(id, quantity) => {
                            // Update cart context or state here
                          }}
                          onRemove={(id) => {
                            // Remove item from cart context or state here
                          }}
                        />
                      ))}
                    </table>
                    <h1>Total: ${cart.getTotalCost().toFixed(2)}</h1>
                    <div>
                      <button className="border bg-gray-700 hover:border-[darkred] hover:bg-[darkred] text-white bg-opacity-90 hover:bg-opacity-100 border-opacity-50 drop-shadow px-10 py-2 rounded inline-flex ">Checkout</button>
                    </div>
                  </>
                ) : (
                  <p className="px-4 py-2">Your cart is empty</p>
                )}
              </div>
            )}

        </nav>
    );
};

export default Navbar;