import React, { useEffect, useState } from "react";
import './RelatedProducts.css'
import Item from '../Item/Item'

const RelatedProducts = (props,i) => {

  const [products, setProducts] = useState([]);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ category: props.category, limit:8 })
  };

  const fetchInfo = () => { 
    fetch(window.$BASE_API_URL + '/products', requestOptions) 
      .then((res) => res.json()) 
      .then((data) => setProducts(data.products))
  }

  useEffect(() => {
    fetchInfo();
  }, []);


  return (
    <div className='relatedproducts'>
      <h1 className=' text-5xl tracking-wide awakening-regular mt-20 mb-10'>Related Products</h1>
      <div className="grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-9 box-border object-top">
        {products.map((e,i) => {
            return  <Item key={e._id} id={e._id} title={e.title} description={e.description} images={[e.images]} price={e.price} />
        })}
      </div>
    </div>
  )
}

export default RelatedProducts
