
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import { Routes, Route, HashRouter } from "react-router-dom";
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import LoginSignup from './Pages/LoginSignup';
import Cart from './Pages/Cart';
import Footer from './Components/Footer/Footer';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Home from './Pages/Home';
import CartProvider from './Context/CartContext';


        /* <QueryClientProvider client={queryClient}> */

function App() {
  return (
    <CartProvider>
      <HashRouter basename="/">
        <Navbar />
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/shop' element={<Home/>}/>
            <Route path='/headwear' element={<ShopCategory category='headwear' />}/>
            <Route path='/patches' element={<ShopCategory category='patches' />}/>
            <Route path='/clothing' element={<ShopCategory category='clothing' />}/>
            <Route path='/product' element={<Product />}>
            <Route path=':productId'  element={<Product />} />
            </Route>
            <Route path='/cart' element={<Cart/>} />
            <Route path='/login' element={<LoginSignup/>} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
          </Routes>
        <Footer />
     </HashRouter>
     </CartProvider>
  );
}

        /* </QueryClientProvider> */
export default App;
