import p1_img from "./promophotos/DSC0143.jpg";
import p2_img from "./promophotos/DSC0210.jpg";
import p3_img from "./promophotos/DSC0345.jpg";
import p4_img from "./promophotos/DSC0436.jpg";
import p5_img from "./promophotos/DSC0534.jpg";
import p6_img from "./promophotos/DSC0562.jpg";
import p7_img from "./promophotos/al-fliction-wearing-bonehead-wear-hat.jpg";
import p8_img from "./promophotos/amy_on_bike.jpg";
import p9_img from "./promophotos/bonehead_wear_beer_bottle.jpg";
import p10_img from "./promophotos/bonehead_wear_arm_wrestle.jpg";
import p11_img from "./promophotos/bonehead_wear_will01.jpg";
import p12_img from "./promophotos/bonehead_wear_will02.jpg";
import p13_img from "./promophotos/playmate_amy_nicole_moore_bonehead_wear.jpg";
import p14_img from "./promophotos/bonehead_wear_babe_wearing_rocker_hat01.jpg";
import p15_img from "./promophotos/bonehead_wear_babe_wearing_rocker_hat02.jpg";
import p16_img from "./promophotos/bonehead_wear_rocker_hat_babes.jpg";
import p17_img from "./promophotos/bonehead_wear_rocker_hat_babes02.jpg";
import p18_img from "./promophotos/bonehead_wear_rocker_fedora_babe_skull.jpg";
import p19_img from "./promophotos/DSC_0768-1.jpg";
import p20_img from "./promophotos/DSC_0833.jpg";
import p21_img from "./promophotos/emily01-bnw-8_5x11.jpg";
import p22_img from "./promophotos/frankndanielle.jpg";
import p23_img from "./promophotos/JAMIE_FIRE.jpg";
import p24_img from "./promophotos/jenny.jpg";
import p25_img from "./promophotos/jessi-lawless.jpg";
import p26_img from "./promophotos/joe-concrete-wall.jpg";
import p27_img from "./promophotos/MODEL-AMYNICOLE.jpg";
import p28_img from "./promophotos/xxx-rocker-hat.jpg";
import p29_img from "./promophotos/15591418_1411315128911216_8255160428159039015_o.jpg";
import p30_img from "./promophotos/23270487_1831081480267910_3393869442018315259_o.jpg";
import p31_img from "./promophotos/23270487_1831998093509582_4056934617165417411_o.jpg";
import p32_img from "./promophotos/23275702_1831082413601150_1907628572641419577_o.jpg";
import p33_img from "./promophotos/dead_west_david_barcai.jpg";
import p34_img from "./promophotos/ewig_frost.jpg";
import p35_img from "./promophotos/laice_babe_rocker_hat_skul.jpg";



export const photos = [
    { src: p30_img, width:1, height:1 },
    { src: p1_img, width:1, height:1 },
    { src: p2_img, width:1, height:1 },
    { src: p3_img, width:1, height:1 },
    { src: p4_img, width:1, height:1 },
    { src: p5_img, width:1, height:1 },
    { src: p6_img, width:1, height:1 },
    { src: p7_img, width:1, height:1 },
    { src: p8_img, width:1, height:1 },
    { src: p9_img, width:1, height:1 },
    { src: p10_img, width:1, height:1 },
    { src: p35_img, width:1, height:1 },
    { src: p11_img, width:1, height:1 },
    { src: p12_img, width:1, height:1 },
    { src: p13_img, width:1, height:1 },
    { src: p14_img, width:1, height:1 },
    { src: p15_img, width:1, height:1 },
    { src: p16_img, width:1, height:1 },
    { src: p17_img, width:1, height:1 },
    { src: p18_img, width:1, height:1 },
    { src: p19_img, width:1, height:1 },
    { src: p20_img, width:1, height:1 },
    { src: p34_img, width:1, height:1 },
    { src: p21_img, width:1, height:1 },
    { src: p22_img, width:1, height:1 },
    { src: p23_img, width:1, height:1 },
    { src: p24_img, width:1, height:1 },
    { src: p25_img, width:1, height:1 },
    { src: p26_img, width:1, height:1 },
    { src: p27_img, width:1, height:1 },
    { src: p28_img, width:1, height:1 },
    { src: p29_img, width:1, height:1 },
    { src: p31_img, width:1, height:1 },
    { src: p32_img, width:1, height:1 },
    { src: p33_img, width:1, height:1 },
    { src: p34_img, width:1, height:1 },
    
  
];