import React from 'react';
import './ShoutOut.css';

function ShoutOut() {
  return (
    <div id="shoutout-wrapper" className=" ">
        <div className="text-center align-center">
            <h2 className="text-5xl tracking-wide awakening-regular  text-white  text-center my-auto"  style={{ "display":"block", "color":"rgba(255,255,255,.75)", "width":"100%" }}>A Quick Shout Out From Us</h2>
            <p className="text-lg leading-loose mt-5  mb-5">
            Special thanks to our followers across the many social media platforms and to our loyal customers whom have spent their hard-earned money on our products.  We put a great deal of time, effort, care and attention to detail in each of our products and hope that you'll care for them as much as we do. It is always a very humbling experience when a client chooses to purchase one of our products and we are forever grateful. 
            </p> 
        </div>
    </div>
  )
}

export default ShoutOut