import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import instagram_icon from '../Assets/instagram_icon.png'
import pintrest_icon from '../Assets/pintester_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'

const Footer = () => {
  return (
    <div className='footer'>
      <ul className="footer-links">
        <li><Link to='/'>Home</Link></li>
        <li><Link to='/'>Shop</Link></li>
        <li>FAQ</li>
        <li><Link to='/about'>About</Link></li>
        <li><Link to='/contact'>Contact</Link></li>
        <li><Link to='/login'>Login</Link></li>
      </ul>
      <div className="footer-social-icons pb-10">
        <div className="footer-icons-container">
            <img src={instagram_icon} alt="" />
        </div>
        <div className="footer-icons-container">
            <img src={pintrest_icon} alt="" />
        </div>
        <div className="footer-icons-container">
            <img src={whatsapp_icon} alt="" />
        </div>
      </div>


      <div className="footer-copyright bg-black pt-10 pb-10">
        <p>Copyright 2012 - { new Date().getFullYear()} © Bonehead Wear LLC <span className="vdiv">|</span> All Rights Reserved </p>
      </div>
    </div>
  )
}

export default Footer
