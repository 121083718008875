// @ts-check  

import { useState } from "react";
import {ChevronLeft , ChevronRight  } from "lucide-react";
import './ImageSlider.css';

type ImageSliderProps = {
  imageUrls: string[]
}

export function ImageSlider({imageUrls}:ImageSliderProps){
  const [imageIndex, setImageIndex] = useState(0);

  function showNextImage(){
    setImageIndex(index => {
      if (index === imageUrls.length -1) return 0;
      return index + 1;
    })
  }

  function showPrevImage(){
    setImageIndex(index => {
      if (index === 0) return imageUrls.length - 1;
      return index - 1;
    })
  }

  return (

  <div className="w-full relative flex">

    <div className="object-cover flex  transition-translate duration-300  ease-in-out">
      {imageUrls.map(url =>(
        <img key={url} src={url} className="img-slider-img object-cover" style={{  translate: `${-100 * imageIndex}%`}} />
      ))}
    </div>
    
    <div onClick={showPrevImage} className="btn-prev block absolute  top-0 left-0 text-left bottom-0 p-4 cursor-pointer stroke-white fill-black w-8 h-8 my-auto"><ChevronLeft size="36px" style={{strokeWidth:'3px'}} /></div>
    <div onClick={showNextImage} className="btn-next block absolute  top-0 right-0 text-right items-end bottom-0 p-4 cursor-pointer stroke-white fill-black w-8 h-8 my-auto"><ChevronRight  size="36px" style={{strokeWidth:'3px'}} /></div>


  </div>
  )


/*
      
    <div className="thubnail-container align-center flex gap-3 relative bottom-0 my-auto  invisible lg:visible">
      {imageUrls.map((url,i) =>(
        <img key={url} onClick={() => setImageIndex(i)} src={url} className="img-thumbnail cursor-pointer object-cover shadow-blue-gray-900/50 rounded-sm" style={{ width:'120px'}} />
      ))}
    </div>
*/

}